import React from "react";
import { WmkLink } from "wmk-link";

import { locale } from "./"

const getEntryPath = (fields) => {
  const entry = fields.entry[locale];
  const slug = entry.fields.slug[locale];
  const path = "/" + slug;
  return path;
};

const RenderEntry = {
  links: ({ fields, children }) => {
    return <WmkLink to={getEntryPath(fields)}>{children}</WmkLink>;
  },
};

export const inlineEntryHyperlink = (node, children) => {
  const { fields } = node.data.target;
  const contentType =
    node.data.target.sys.contentType && node.data.target.sys.contentType.sys.id;

  const Jsx = RenderEntry[contentType];

  return Jsx ? (
    <Jsx fields={fields}>{children}</Jsx>
  ) : (
    <em className="hyperlink-error error">
      {children}
      {console.log("inline hyperlink " + contentType + " not set")}
    </em>
  );
};
