import React from "react";
import { WmkImage, Img } from "wmk-image";
import { PageTitleBar } from "../elements/PageTitleBar";

const PortalHero = ({
  children,
  hero,
}: {
  children: React.ReactNode;
  hero: Img;
}) => {
  return (
    <div
      style={{ position: "relative", maxHeight: "350px", overflow: "hidden" }}
    >
      <WmkImage image={hero} />
      <PageTitleBar>{children}</PageTitleBar>
    </div>
  );
};

export default PortalHero;
