import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { CalTitle } from "../";
import { timeFormat } from "../../../../vars/Time";
import RichText from "../../../richText/RichText";
import { helpers } from "../Calendar";

const Styled = {};
Styled.EventPreview = styled(Row)`
  max-width: 350px;
  .preview-date {
  }
  padding-bottom: 0.5vh;
  margin-bottom: 0.5vh;
  border-bottom: ${({ noline }) => (noline ? "none" : "1px solid #ccc")};
`;
Styled.DropdownTitle = styled.button`
  cursor: ${({ opens }) => (opens ? "pointer" : "default !important")};
  background: none;
  appearance: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 0;
  @media screen {
    :focus {
      outline: none;
    }
  }
  h6 {
    margin: 0;
    line-height: 1.25;
  }
`;
Styled.DropdownDate = styled.div``;
Styled.DropdownContent = styled(Col)`
  &.open {
    transition: all 0.4s ease;
    height: auto;
    opacity: 1;
    & > div {
      transition: all 0.4s ease;
      height: auto;
    }
  }
  &.shut {
    transition: all 0.5s ease;
    height: 0;
    min-height: 0;
    opacity: 0;
    & > div {
      transition: all 0.4s ease;
      heihgt: 0;
    }
  }
`;

Styled.Date = styled.span`
  font-size: 0.8rem;
`;

export const CalPreview = ({ start, end, title, description, dst }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const startStr = ""; //start.tz('America/Chicago').format("M/D/YY @ ");
  const endStr =
    end && helpers.isSameDay(start, end)
      ? timeFormat.format(start) + "-" + timeFormat.format(end)
      : timeFormat.format(start);
  const { json } = description ? description : { json: null };
  const dstStr = dst ? "CDT" : "CST";
  return (
    <Styled.EventPreview className="flex-column" noline="true">
      <Col>
        <Styled.DropdownTitle
          onClick={handleExpand}
          opens={(json && description.json) || description}
        >
          <CalTitle smaller>{title}</CalTitle>
        </Styled.DropdownTitle>
      </Col>
      <Col>
        <Styled.Date>{startStr + endStr + " " + dstStr}</Styled.Date>
      </Col>
      <Styled.DropdownContent className={isExpanded ? "open" : "shut"}>
        {json ? (
          <RichText json={description.json} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </Styled.DropdownContent>
    </Styled.EventPreview>
  );
};
