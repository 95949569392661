import React from "react";
import { Heading6 } from "../../common/ntmwd";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../vars/palette";

const Styled: { [key: string]: Function } = {};
Styled.Card = styled(Card)`
  padding: 1vh 2vh;
  h6 {
    color: ${colors.hex("text")};
  }
  margin: 0.5vh 0 1.5vh 0;
  &.card {
    background-color: ${colors.rgba("coaccent", 0.25)};
  }
`;

const NextNotice = ({ notice }: { notice: string }) => {
  console.log(notice)
  return (
    <Styled.Card>
      <Heading6>{notice}</Heading6>
    </Styled.Card>
  );
};

export default NextNotice;
