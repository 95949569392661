import React from "react";
import { detect } from "detect-browser";
import { IEFallbackStyles } from "./IEFallbackStyle";

const IEFallback = ({ children }) => {
  const browser = detect();
  const { name } = browser ? browser : "error";
  //console.log(browser)
  return name === "ie" ? (
    <IEFallbackStyles className={"browser-" + name}>{children}</IEFallbackStyles>
  ) : (
    <>{children}</>
  );
};

export default IEFallback;
