import React from "react";
import { Heading3, Heading5 } from "../../ntmwd";
import styled from "styled-components";
import { colors } from "../../../../vars/palette";

const Styled = {};

Styled.Date = styled.div`
  color: ${colors.hex("secondary")};
  h3,
  h5 {
    border-bottom: 2px solid ${colors.hex("accent")};
    padding-bottom: 0.75vh;
  }
`;

const sizes = {
  H3: Heading3,
  H5: Heading5,
};

export const CalDate = ({ children, smaller, style }) => {
  const size = smaller ? "H5" : "H3";
  const Jsx = sizes[size];
  return (
    <Styled.Date style={style}>
      <Jsx>{children}</Jsx>
    </Styled.Date>
  );
};
