import get from "lodash/get";
import * as React from "react";
import { RichText } from "../../../classes/RichText";

export const NullComponent = () => <></>;

export class EmbeddedBlock {
  private props?: {};
  private Comp?: React.ComponentClass | React.FunctionComponent;
  private blockId: string;
  constructor(node: object) {
    const data = get(node, `references`);
    this.props = undefined;
    this.Comp = NullComponent;
    this.blockId = get(data, `blockId`);
    switch (true) {
      default:
        console.log(`no block component found for data:`, node);
    }
  }
  render() {
    const Jsx = this.Comp;
    const props = this.props;
    return <Jsx {...props} />;
  }
  setProps(props: {}) {
    this.props = props ? props : undefined;
  }
  setComp(Comp) {
    this.Comp = Comp ? Comp : NullComponent;
  }
}
