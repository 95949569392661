import styled from "styled-components";

export const IEFallbackStyles = styled.div`
  .sq-btn {
    display: block;
    & > .flex-column.row {
      display: block;
    }
  }
  .notices .flex-column.row {
    display: block !important;
  }
  .cal-module {
    display: block !important;
    .flex-column.row {
      display: block !important;
    }
  }
  div[class^="CalendarPreview"] {
    &.row {
      display: block !important;
    }
  }
`;
