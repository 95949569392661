import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Heading1 } from "../index";
import { colors } from "../../../../vars/palette";

const Styled = {};
Styled.TitleBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1vh 0 0.5vh 0;
  background: ${colors.rgba("tertiary", 0.85)};
  border-bottom: 0.5vh solid ${colors.hover("tertiary", 0.4)};
  width: 100%;
  h1 {
    color: ${colors.hex("white")};
    margin: 0;
  }
`;

export const PageTitleBar = ({ children }) => {
  return (
    <Styled.TitleBar>
      <Container>
        <Row>
          <Col>
            <Heading1 din>{children}</Heading1>
          </Col>
        </Row>
      </Container>
    </Styled.TitleBar>
  );
};

PageTitleBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};
