import React, { useState, useEffect } from "react";
import ReactCalendar from "react-calendar";
import styled from "styled-components";
import { CalPreview, CalDate } from "./";
import { useStaticEvents } from "../../context/EventProvider";
import { dateFormat, timeFormat } from "../../../vars/Time";
import { colors } from "../../../vars/palette";


const Styled = {};
Styled.CalenderWidget = styled.div`
  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid ${colors.hex("coaccent")};
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: ${colors.hex("scorpion")};
  }
  .react-calendar__navigation button[disabled] {
    background-color: ${colors.hex("alabaster")};
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: ${colors.hex("text")};
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: ${colors.hex("alabaster")};
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
`;
Styled.Calendar = styled(ReactCalendar)`
  .has-event {
    background: ${colors.hex("tertiary")};
    color: ${colors.hex("white")};
  }
  .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    color: ${colors.hex("primary")};
    font-weight: bold;
  }
  .react-calendar__navigation__arrow {
    color: ${colors.hex("secondary")};
  }
`;

const Calendar = () => {
  const [calendarDate, setCalendarDate] = useState();
  const [activeDates, setActiveDates] = useState(
    helpers.matching(useStaticEvents(), new Date())
  );
  const activeDate = calendarDate;
  const handleChange = (date) => {
    setCalendarDate(date);
    setActiveDates(helpers.matching(allEvents, date));
  };
  const allEvents = useStaticEvents();
  //console.log(allEvents)
  const handleNav = (e) => {
    //console.log("nav", e);
    //setCalendarDate(null);
  };
  useEffect(() => {
    setCalendarDate(new Date());
  }, [setCalendarDate]);
  return (
    <Styled.CalenderWidget>
      <Styled.Calendar
        calendarType={"US"}
        onChange={handleChange}
        onActiveStartDateChange={handleNav}
        value={calendarDate}
        tileClassName={({ date }) => {
          let classes = [];
          allEvents.forEach((event) => {
            if (helpers.isSameDay(event.start, date)) {
              classes.push("has-event");
            }
            // if (event.public) classes.push("board-mtg");
            // if (event.type === "Workshop/Conference") classes.push("conf");
          });
          const ret = [...new Set(classes)];
          return ret.join(" ");
        }}
      />
      <div style={{ padding: "0 1.5vh" }}>
        {activeDate ? (
          <CalDate smaller style={{ marginTop: "2vh" }}>
            {dateFormat.format(activeDate)}
          </CalDate>
        ) : null}
        {activeDate && activeDates && activeDates.length ? (
          activeDates.map((event) => {
            const { start, end, title, description, dst } = event;
            return start && helpers.isSameDay(activeDate, start) ? (
              <CalPreview
                key={
                  dateFormat.format(start) +
                  timeFormat.format(start)
                }
                start={start}
                end={end}
                title={title}
                description={description}
                dst={dst}
              />
            ) : null;
          })
        ) : (
          <em>No events this day.</em>
        )}
      </div>
    </Styled.CalenderWidget>
  );
};

export default Calendar;

export const helpers = {};
helpers.matching = (events, activeDate, scope) => {
  //const mom = moment(activeDate);
  const _scope = scope ? scope : "day";
  const matching = [];
  events.forEach((event) => {
    if (_scope === "day") {
      if (helpers.isSameDay(event.start, activeDate)) {
        matching.push(event);
      }
    } else {
      if (helpers.isSameMonth(event.start, activeDate)) {
        matching.push(event);
      }
    }
  });
  matching.sort((a, b) => {
    return helpers.isAfter(a.start, b.start) ? 1 : -1;
  });
  return matching;
};
helpers.isSameDay = (first, second) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};
helpers.isSameMonth = (first, second) => {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth()
  );
};
helpers.isAfter = (first, second) => {
  return first.getTime() > second.getTime();
};
helpers.isBefore = (first, second) => {
  return first.getTime() < second.getTime();
};
