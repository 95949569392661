import * as React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "../../../../vars/palette";
import { Img, WmkImage } from "wmk-image";
import CSS from "csstype";
import { RichText } from "../../../../classes/RichText";
import { RichTextReact } from "../../../util/RichText/RichTextReact";

//const BUTTON_SIZE = 200;

const Styled: { [key: string]: Function } = {};
Styled.Wrapper = styled(Row)`
  padding-bottom: 2vh;
  margin-bottom: 2vh;
  border-bottom: 1px dashed ${colors.hex("tertiary")};
`;
Styled.Button = styled(WmkLink)<{ size: number }>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 69}px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.hex("white")};
  padding: 3vh;
  text-align: center;
  transition: all 0.3s ease;
  text-decoration: none;
  &:hover {
    color: ${colors.hex("white")};
    text-decoration: none;
    transition: all 0.3s ease;
  }
  &.blue {
    background: ${colors.hex("primary")};
    &:hover {
      background: ${colors.hover("primary")};
    }
  }
  &.cyan {
    background: ${colors.hex("secondary")};
    &:hover {
      background: ${colors.hover("secondary")};
    }
  }
  &.teal {
    background: ${colors.hex("tertiary")};
    &:hover {
      background: ${colors.hover("tertiary")};
    }
  }
  &.green {
    background: ${colors.hex("tertiary")};
    &:hover {
      background: ${colors.hover("tertiary")};
    }
  }
  &.haze {
    background: ${colors.hex("greenHaze")};
    &:hover {
      background: ${colors.hover("greenHaze")};
    }
  }
`;

Styled.Title = styled(Col)`
  font-weight: bold;
`;

Styled.Description = styled(Col)`
  display: flex;
  align-items: center;
  color: ${colors.hex("text")};
`;

type withDescriptionProps = {
  hideTitle?: boolean;
  title: string;
  content: RichText;
  children: React.ReactNode;
  style?: CSS.Properties;
};

const WithDescription = ({
  hideTitle,
  title,
  content,
  children,
  style,
}: withDescriptionProps) => {
  return hideTitle ? (
    <div style={style}>{children}</div>
  ) : (
    <Styled.Wrapper key={title} className="g-0">
      <Col xs="auto">{children}</Col>
      <Styled.Description style={{ padding: "0 2vh" }}>
        <RichTextReact content={content} />
      </Styled.Description>
    </Styled.Wrapper>
  );
};

type squareButtonProps = {
  button: {
    title: string;
    description: RichText;
    linkType: string;
    path?: string;
    url: string;
    icon: Img;
  };
  index: number;
  size: number;
  hideTitle?: boolean;
  style?: CSS.Properties;
};

export const SquareButton = ({
  button,
  index,
  size,
  hideTitle,
  style,
}: squareButtonProps) => {
  const { title, description, linkType, path, url, icon } = button;
  const isInternal = linkType === "Internal";
  const classes = ["sq-btn"];
  if (index === 0) classes.push("blue");
  if (index === 1) classes.push("teal");
  if (index === 2) classes.push("cyan");
  if (index === 3) classes.push("haze");
  return (
    <WithDescription
      hideTitle={hideTitle}
      title={title}
      content={description}
      style={style}
    >
      <Styled.Button
        to={isInternal ? path : url}
        target={isInternal ? undefined : "blank"}
        className={classes.join(" ")}
        size={size}
      >
        <Row className="flex-column">
          <Col>
            <WmkImage
              aria-label={title}
              image={icon}
              //title={title}
              style={{
                maxWidth: `${size / (hideTitle ? 1.25 : 2.5)}px`,
                maxHeight: `${size / (hideTitle ? 1.25 : 2.5)}px`,
                height: `${size / (hideTitle ? 1.25 : 2.5)}px`,
                width: "100%",
              }}
            />
          </Col>
          {!hideTitle && <Styled.Title>{title}</Styled.Title>}
        </Row>
      </Styled.Button>
    </WithDescription>
  );
};
