import React from "react";
import { Heading4, Heading6 } from "../../ntmwd";
import styled from "styled-components";
import { colors } from "../../../../vars/palette";

const Styled = {};

Styled.Date = styled.div`
  color: ${colors.hex("primary")};
  h4,
  h6 {
  }
`;

const sizes = {
  H4: Heading4,
  H6: Heading6,
};

export const CalTitle = ({ children, smaller }) => {
  const size = smaller ? "H6" : "H4";
  const Jsx = sizes[size];
  return (
    <Styled.Date>
      <Jsx>{children}</Jsx>
    </Styled.Date>
  );
};
