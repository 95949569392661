import React from "react";
import { WmkLink } from "wmk-link";
import { locale } from "./"

export const inlineAssetHyperlink = (node, children) => {
  const { nodeType, data } = node;
  const url = data.target.fields.file[locale].url;
  let Jsx = null;
  switch (nodeType) {
    case "asset-hyperlink":
      Jsx = ({ children }) => (
        <WmkLink to={url} target="self" className="inline">
          {children}
        </WmkLink>
      );
      break;
    default:
      console.log(nodeType + " not set");
  }
  return <Jsx>{children}</Jsx>;
};
