import React from "react";
import { Row, Card } from "react-bootstrap";
import { WmkLink } from "wmk-link";

const AuthCheck = () => {
  return (
    <Row>
      <Card
        style={{
          display: "inline-block",
          padding: "2vh",
          margin: "4vh auto",
        }}
      >
        You must be logged in to view this page.{" "}
        <WmkLink to="/login">Login Here</WmkLink>
      </Card>
    </Row>
  );
};

export default AuthCheck;
