import { graphql, useStaticQuery } from "gatsby";
import { isDaylightTime } from "../../vars/Time";
import { EventsFields } from "../../fragments/nodeEventsFields";

// const query = graphql`
//   {
//     _public: allTribeEvents(
//       filter: { title: { eq: "Board of Directors Meeting" } }
//     ) {
//       edges {
//         node {
//           ...NodeTribeEventsFields
//         }
//       }
//     }
//     _private: allContentfulBoardResourcesBoardEvents {
//       edges {
//         node {
//           ...NodeEventsFields
//         }
//       }
//     }
//   }
// `;

// query without events
const query = graphql`
  {
    _private: allContentfulBoardResourcesBoardEvents {
      edges {
        node {
          ...NodeEventsFields
        }
      }
    }
  }
`;

export interface PortalEvent {
  title: string;
  public: boolean;
  dst: boolean;
  timeOffset: number;
  start: Date;
  end: Date;
}

export const useStaticEvents = (): PortalEvent[] => {
  const data: { _private: { edges: { node: EventsFields }[] } } =
    useStaticQuery(query);
  const { _private } = data;
  // const _public = get(data, `_public.edges`, []);
  // const publicEvents = _public.map((e) => {
  //   const _e = { ...e.node };
  //   const { start_date, end_date } = e.node;
  //   _e.location = get(_e, `venue.venue`) + ", " + get(_e, `venue.city`);
  //   _e.description = get(e, `node.description`);
  //   _e.public = true;
  //   _e.type = "General Board Meeting";
  //   _e.dst = isDaylightTime(new Date(start_date));
  //   _e.timeOffset = _e.dst ? 5 : 6;
  //   _e.start = new Date(start_date + `.000-0${_e.timeOffset}`); // moment(e.node.start_date + ".000-05"); // timezone convert?
  //   _e.end = end_date && new Date(end_date + `.000-0${_e.timeOffset}`);

  //   return _e;
  // });

  const privateEvents: PortalEvent[] = _private.edges.map((e) => {
    const __e = { ...e.node };
    const _e: PortalEvent = {};
    const { start_date, end_date } = __e;
    // Hide Datestamps due to limitation of showing date column in Contentful
    const datestamp = __e.title.slice(__e.title.length - 8, __e.title.length);
    const match = datestamp.match(/[0-9]/g);
    const title = __e.title;
    if (match && match.length) {
      _e.title = title.replace(datestamp, "");
    } else {
      _e.title = title;
    }
    _e.public = false;
    _e.dst = isDaylightTime(new Date(start_date));
    _e.timeOffset = _e.dst ? 5 : 6;
    _e.start = new Date(start_date + `-0${_e.timeOffset}:00`); // + "-05:00");
    _e.end = end_date && new Date(end_date + `-0${_e.timeOffset}:00`); // : start_date; //.add(1, "hours"); // need to add an hour here
    return _e;
  });
  return [].concat(
    //publicEvents,
    privateEvents
  );
};
