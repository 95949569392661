import React from "react";
import { WmkLink } from "wmk-link";


export const inlineHyperlink = (node, children) => {
  //console.log(children)
  return (
    <>
      <WmkLink to={node.data.uri}>
        {children}
      </WmkLink>
    </>
  );
};
