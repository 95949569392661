import * as React from "react";
import { Heading5 } from "../../common/ntmwd";
import { Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import NextNotice from "./NextNotice";
import NextBoardMeeting from "./NextBoardMeeting";
import { colors } from "../../../vars/palette";

const Notices = () => {
  const data: NoticesQuery = useStaticQuery(query);
  const notices = data.notices;
  const _notices = [...notices.edges.map((e) => e.node)];
  return (
    <div className="notices">
      <Heading5 din style={{ color: colors.hex("tertiary") }}>
        Important Board Notices:
      </Heading5>
      <Row className="flex-column">
        <Col>
          {_notices && _notices.length > 0
            ? _notices.map((notice) => {
                const { title } = notice;
                return title.match(/schema/i) ? null : (
                  <NextNotice key={title} notice={title} />
                );
              })
            : null}
        </Col>
        <Col>
          <NextBoardMeeting />
        </Col>
      </Row>
    </div>
  );
};

export default Notices;

export interface NoticesQuery {
  notices: {
    edges: { node: { title: string } }[];
  };
}

const query = graphql`
  {
    notices: allContentfulBoardResourcesNotices(
      sort: { order: ASC, fields: updatedAt }
      filter: {title: {ne: "Sample Board Meeting Notice - DO NOT DELETE OR UNPUBLISH"}}
    ) {
      edges {
        node {
          ...NodeNoticesFields
        }
      }
    }
  }
`;
