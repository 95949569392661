import React from "react";
import { Heading6 } from "../../common/ntmwd";
// import { useStaticEvents } from "../../context/EventProvider";
// import { dateFormat } from "../../../vars/Time";
import { colors } from "../../../vars/palette";
// import get from "lodash/get";

const NextBoardMeeting = () => {
  // const allEvents = useStaticEvents();
  // const pub = [];
  // allEvents.forEach((ev) => {
  //   if (ev.public) pub.push(ev);
  // });
  // const next = pub[0];
  // const nextStart = get(next, `start`);
  // const nextDate = new Date(nextStart);
  // console.log("next:", next, "start:", nextStart, "date:", nextDate);
  return (
    <Heading6 din style={{ color: colors.hex("primary") }}>
      {/* {next
        ? `Next board meeting on ${dateFormat.format(nextStart)}`
        : "See calendar below for upcoming Board and Committee meetings."} */}
      See calendar below for upcoming Board and Committee meetings.
    </Heading6>
  );
};

export default NextBoardMeeting;
