import { BLOCKS } from "@contentful/rich-text-types";
import * as React from "react";
import get from "lodash/get";
import { EmbeddedBlock } from "./Blocks";
import { WmkImage, Img } from "wmk-image";

export const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = get(node, `references`);
      return (
        <WmkImage
          image={new Img({ ...image })}
          style={{ margin: "0 0 2vh 0" }}
        />
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      // console.log('node from BLOCKS.EMBEDDED_ENTRY: ', node);
      const entry = new EmbeddedBlock(node);
      return entry.render();
    },
  },
  renderText: (text) => text.replace("!", "?"),
};
