import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Heading6, Heading3 } from "../../common/ntmwd";
import BoardCalendar from "../../common/Calendar/Calendar";
import { WmkLink } from "wmk-link";
import { graphql, useStaticQuery } from "gatsby";
import { SquareButton } from "../../common/ui";
import styled from "styled-components";
import Notices from "./Notices";
import { colors } from "../../../vars/palette";
import { SquareButtonsFields } from "../../../fragments/nodeSquareButtonsFields";
import { RichText } from "../../../classes/RichText";
import { Img } from "wmk-image";

const Styled: { [key: string]: Function } = {};
Styled.SmallButtons = styled(Row)`
  padding: 1rem;
  & > a {
    margin-right: 2vh;
  }
`;

const BackButtons = () => {
  const data: BoardNoticesQuery = useStaticQuery(query);
  const _buttons = [...data.buttons.edges].map((e) => e.node);

  return (
    <Container>
      <WmkLink to="/">
        <Heading6 style={{ color: colors.hex("secondary") }}>
          Back to the Board Resources Page
        </Heading6>
      </WmkLink>
      <Styled.SmallButtons>
        <Row>
          {_buttons.map((button, i) => {
            return (
              <Col
                key={button.title}
                style={{ padding: 0, margin: ` 0 .25rem .25rem 0` }}
                xs="auto"
              >
                <SquareButton
                  button={{
                    title: button.title,
                    description: new RichText(button.description),
                    linkType: button.linkType,
                    path: button.path,
                    url: button.url,
                    icon: new Img(button.icon),
                  }}
                  index={i}
                  hideTitle
                  size={72}
                />
              </Col>
            );
          })}
        </Row>
      </Styled.SmallButtons>
    </Container>
  );
};

const Sidebar = ({ notices }: { notices?: boolean }) => {
  return (
    <Row className="flex-column">
      {notices ? <Notices /> : <BackButtons />}
      <Heading3 din upper style={{ color: colors.hex("tertiary") }}>
        {notices ? "A Quick Look Ahead" : "Board Calendar"}
      </Heading3>
      <BoardCalendar />
    </Row>
  );
};

export default Sidebar;

export interface BoardNoticesQuery {
  buttons: {
    edges: { node: SquareButtonsFields }[];
  };
}

const query = graphql`
  {
    buttons: allContentfulSettingsSquareButtons(
      sort: { order: ASC, fields: sortOrder }
      filter: { title: { ne: "View Board Events" } }
    ) {
      edges {
        node {
          ...NodeSquareButtonsFields
        }
      }
    }
  }
`;
