import { helpers } from "../components/common/Calendar/Calendar";

export const dateFormat = new Intl.DateTimeFormat("en-US", {
  timeZone: "America/Chicago",
  year: "numeric",
  month: "long",
  day: "numeric",
});
export const timeFormat = new Intl.DateTimeFormat("en-US", {
  timeZone: "America/Chicago",
  hour: "numeric",
  minute: "numeric",
});

const getDSTStartDate = (year) => {
  const firstPossibleSunday = new Date(year, 2, 7);
  firstPossibleSunday.setDate(7 + (7 - firstPossibleSunday.getDay()));
  return firstPossibleSunday;
};

const getDSTEndDate = (year) => {
  return new Date(year, 10, 1 + ((0 - new Date(year, 10, 1).getDay() + 7) % 7));
};

export const isDaylightTime = (date) => {
  const dstStart = getDSTStartDate(date.getFullYear());
  const dstEnd = getDSTEndDate(date.getFullYear());
  const compareMonth = date.getMonth();
  if (compareMonth >= 3 && compareMonth <= 9) {
    return true;
  }
  if (compareMonth <= 1 || compareMonth === 11) {
    return false;
  }
  if (compareMonth === 2) {
    return helpers.isAfter(date, dstStart);
  }
  if (compareMonth === 10) {
    return helpers.isBefore(date, dstEnd);
  }
  // Shouldn't get to the below logic, which is broken and doesn't work properly
  //console.log(helpers.isAfter(date,dstStart),!helpers.isBefore(date,dstEnd),date,dstEnd)
  return (
    // helpers.isBefore(date, getDSTStartDate(date.getFullYear())) ||
    // helpers.isAfter(getDSTEndDate(date.getFullYear()), date)
    helpers.isAfter(date, dstStart) || !helpers.isBefore(date, dstEnd)
  );
};
