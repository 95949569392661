import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

const Styled: { [key: string]: Function } = {};
Styled.LeftColumn = styled(Col)`
  -webkit-flex: initial;
`;
Styled.RightColumn = styled(Col)`
  & > .row {
    padding: 0 1rem;
  }
`;

const TwoColumnLayout = ({ Main, Sidebar }) => {
  return (
    <Container>
      <Row style={{ marginTop: "3vh" }}>
        <Styled.LeftColumn xs={12} md={7} lg={8}>
          <Main />
        </Styled.LeftColumn>
        <Styled.RightColumn xs={12} md={5} lg={4}>
          <Sidebar />
        </Styled.RightColumn>
      </Row>
    </Container>
  );
};

export default TwoColumnLayout;
