import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { SquareButton } from "./SquareButton";
import { SquareButtonsFields } from "../../../../fragments/nodeSquareButtonsFields";
import { RichText } from "../../../../classes/RichText";
import { Img } from "wmk-image";

export const SquareButtons = () => {
  const data: {
    buttons: { edges: { node: SquareButtonsFields }[] };
  } = { ...useStaticQuery(query) };
  const _buttons = data.buttons.edges.map((e) => e.node);
  return (
    <>
      {_buttons.map((button, i) => {
        const desc = new RichText({ ...button.description });
        return (
          <SquareButton
            key={button.title}
            button={{
              title: button.title,
              description: desc,
              linkType: button.linkType,
              path: button.path,
              url: button.url,
              icon: new Img(button.icon),
            }}
            index={i}
            size={200}
          />
        );
      })}
    </>
  );
};

const query = graphql`
  {
    buttons: allContentfulSettingsSquareButtons(
      sort: { order: ASC, fields: sortOrder }
    ) {
      edges {
        node {
          ...NodeSquareButtonsFields
        }
      }
    }
  }
`;
